import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#app';
import type { LooseObject } from '~/types/generic';
import { useLivePreviewStore } from '~/stores/live-preview';
import { param } from '~/utils/url';

interface Resource extends LooseObject {
  id: string;
  title: string;
  url?: string;
  redirect?: string;
  blueprint: {
    handle: string;
  };
  seo?: Array<LooseObject>;
}

interface State {
  isLoading: boolean;
  currentResource: Resource | null;
}

interface ResourceResponse extends LooseObject {
  data: Resource[];
}

export const useResourcesStore = defineStore('resource', {
  state: (): State => ({
    isLoading: false,
    currentResource: null,
  }),

  getters: {
    getCurrentResource: (state: State) => {
      const livePreview = useLivePreviewStore();
      const livePreviewEntry = livePreview.entry;
      return livePreviewEntry || state.currentResource || {};
    },

    hasCurrentResource: (state: State) => {
      return !!(state.currentResource && state.currentResource.id);
    },
  },

  actions: {
    setIsLoading(isLoading: boolean) {
      this.isLoading = isLoading;
    },

    setCurrentResource(resource = null) {
      this.currentResource = resource;
    },

    async loadSingle(slug: string) {
      if (this.hasCurrentResource) {
        return this.currentResource;
      }

      this.isLoading = true;

      try {
        const runtime = useRuntimeConfig();
        const headerName = runtime.generateHeader;
        const headerValue = runtime.generateValue;
        const params = param({
          'filter[slug:equals]': slug,
        });
        const response: ResourceResponse = await $fetch(`${useRuntimeConfig().public.apiUrl}/api/collections/resources/entries?${params}`, {
          headers: {
            [headerName]: headerValue,
          },
        });
        return response.data && response.data.length ? response.data[0] : null;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },

    setResource(resource: Resource) {
      this.currentResource = resource;
    },
  },
});
