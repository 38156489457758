export const GRANTS_TYPE_INDIVIDUAL = 'individual';
export const GRANTS_TYPE_ORGANISATION = 'organisation';

export const GRANTS_RESULTS_PER_PAGE = 12;
export const GRANTS_INPUT_DEBOUNCE_MS = 300;

export const TAXONOMY_TYPE = 'grant_type';
export const TAXONOMY_REGION = 'grants_regions';
export const TAXONOMY_SECTORS = 'sectors';
export const BOOLEAN_OPEN_NOW = 'applications_open_now';
