export function debounce<T extends unknown[], U>(callback: (...args: T) => PromiseLike<U> | U, wait: number): () => Promise<U> {
  let timer: number;

  return (...args: T): Promise<U> => {
    clearTimeout(timer);
    return new Promise((resolve) => {
      //@ts-expect-error
      timer = setTimeout(() => resolve(callback(...args)), wait);
    });
  };
}
