import routerOptions0 from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/build/repo/app/router.options.ts";
const configRouterOptions = {
  linkActiveClass: "is-current",
  linkExactActiveClass: "is-exact",
  strict: false,
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}