<template>
  <transition :name="animation" @before-enter="beforeEnter" @after-enter="afterEnter" @before-leave="beforeLeave" @after-leave="afterLeave">
    <div
      v-if="!destroyed"
      v-show="isActive"
      v-trap-focus="trapFocus"
      class="popup is-active"
      :class="[
        {
          'is-full-screen': fullScreen,
          'no-pad': paddingless,
          'is-wide': wide,
          'is-clickable-through': clickThroughToClose,
          'is-middle': middle,
          'is-min-height': minHeight,
          'is-rounded': rounded,
        },
      ]"
      tabindex="-1"
      :role="ariaRole"
      :aria-label="ariaLabel"
      :aria-modal="ariaModal"
    >
      <div class="popup__bg" @click="cancel('outside')" />
      <div ref="content" class="popup__outer">
        <button v-if="showX" v-show="!animating || !closeButtonAnimation" type="button" class="popup__close" @click="cancel('x')">
          <span v-if="showCloseButtonText" class="popup__close-text"> Close </span>
          <span class="popup__close-icon">
            <pt-icon name="close" :size="20" />
          </span>
        </button>
        <div class="popup__inner">
          <slot :can-cancel="canCancel" :close="close" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';
import trapFocus from '~/directives/trap-focus';

const cancelOptions = ['escape', 'x', 'outside', 'button'];

export default defineComponent({
  directives: {
    trapFocus,
  },
  props: {
    modelValue: Boolean,
    animation: {
      type: String,
      default: 'fade',
    },
    canCancel: {
      type: [Array, Boolean],
      default: () => cancelOptions,
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    fullScreen: Boolean,
    paddingless: Boolean,
    wide: Boolean,
    clickThroughToClose: Boolean,
    middle: Boolean,
    minHeight: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    trapFocus: {
      type: Boolean,
      default: true,
    },
    autoFocus: {
      type: Boolean,
      default: true,
    },
    ariaRole: {
      type: String,
      default: '',
      validator: (value: string) => ['', 'dialog', 'alertdialog'].includes(value),
    },
    ariaModal: Boolean,
    ariaLabel: {
      type: String,
      default: '',
    },
    destroyOnHide: {
      type: Boolean,
      default: true,
    },
    closeButtonAnimation: {
      type: Boolean,
      default: true,
    },
    showCloseButtonText: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['cancel', 'update:modelValue', 'close', 'before-enter', 'after-enter', 'before-leave', 'after-leave'],
  setup(props, { emit }) {
    const isActive = ref(props.modelValue || false);
    const animating = ref(!props.modelValue);
    const destroyed = ref(!props.modelValue);

    const cancelOptionsComputed = computed(() => {
      return typeof props.canCancel === 'boolean' ? (props.canCancel ? cancelOptions : []) : props.canCancel;
    });

    const showX = computed(() => cancelOptionsComputed.value.includes('x'));

    watch(
      () => props.modelValue,
      (value) => {
        isActive.value = value;
      }
    );

    watch(isActive, (value) => {
      if (value) {
        destroyed.value = false;
      }
      handleScroll();
      nextTick(() => {
        if (value && props.autoFocus) {
          (document.querySelector('.popup.is-active') as HTMLDivElement)?.focus();
        }
      });
    });

    onMounted(() => {
      if (typeof window !== 'undefined') {
        document.addEventListener('keyup', keyPress);
        handleScroll();
      }
    });

    onBeforeUnmount(() => {
      scrollUnlock();
    });

    const handleScroll = () => {
      const storedScrollY = window.scrollY;
      nextTick(() => {
        if (isActive.value) {
          document.body.classList.add('is-popup-open');
          document.body.style.setProperty('top', `${window.scrollY * -1}px`);
          document.body.style.setProperty('right', '0');
        } else {
          scrollUnlock();
          document.body.style.setProperty('top', '');
          document.body.style.setProperty('right', '');
          window.scrollTo(0, storedScrollY);
        }
      });
    };

    const cancel = (...args) => {
      const method = args[0];

      if (!cancelOptionsComputed.value.includes(method)) return;

      emit('cancel', args);
      props.onCancel.apply(null, args);
      close();
    };

    const close = () => {
      emit('close');
      emit('update:modelValue', false);
    };

    const keyPress = ({ key }) => {
      if (isActive.value && (key === 'Escape' || key === 'Esc')) {
        cancel('escape');
      }
    };

    const beforeEnter = () => {
      destroyed.value = false;
      emit('before-enter');
    };

    const afterEnter = () => {
      animating.value = false;
      emit('after-enter');
    };

    const beforeLeave = () => {
      animating.value = true;
      emit('before-leave');
    };

    const afterLeave = () => {
      if (props.destroyOnHide) {
        destroyed.value = true;
      }
      emit('after-leave');
    };

    const scrollUnlock = () => {
      document.body.classList.remove('is-popup-open');
    };

    return {
      isActive,
      animating,
      destroyed,
      cancelOptionsComputed,
      showX,
      handleScroll,
      cancel,
      close,
      keyPress,
      beforeEnter,
      afterEnter,
      beforeLeave,
      afterLeave,
    };
  },
});
</script>
