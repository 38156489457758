export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"preload","as":"font","href":"/fonts/Proxima-Soft.woff2","type":"font/woff2","crossorigin":""},{"rel":"preload","as":"font","href":"/fonts/Proxima-Soft-It.woff2","type":"font/woff2","crossorigin":""},{"rel":"preload","as":"font","href":"/fonts/Proxima-Soft-Light.woff2","type":"font/woff2","crossorigin":""},{"rel":"preload","as":"font","href":"/fonts/Proxima-Soft-Light-It.woff2","type":"font/woff2","crossorigin":""},{"rel":"preload","as":"font","href":"/fonts/Proxima-Soft-Semibold.woff2","type":"font/woff2","crossorigin":""},{"rel":"preload","as":"font","href":"/fonts/Proxima-Soft-Semibold-It.woff2","type":"font/woff2","crossorigin":""},{"rel":"preload","as":"font","href":"/fonts/Proxima-Soft-Bold.woff2","type":"font/woff2","crossorigin":""},{"rel":"preload","as":"font","href":"/fonts/Proxima-Soft-Bold-It.woff2","type":"font/woff2","crossorigin":""},{"rel":"preload","as":"font","href":"/fonts/Proxima-Soft-Medium.woff2","type":"font/woff2","crossorigin":""},{"rel":"preconnect","href":"https://connect.facebook.net","as":"script","crossorigin":"anonymous"},{"rel":"preconnect","href":"https://www.googletagmanager.com","as":"script","crossorigin":"anonymous"},{"rel":"preconnect","href":"https://api.publictrust.co.nz","as":"script","crossorigin":"anonymous"},{"rel":"preconnect","href":"https://www.google-analytics.com","as":"script","crossorigin":"anonymous"},{"rel":"preconnect","href":"https://static.hotjar.com","as":"script","crossorigin":"anonymous"},{"rel":"preconnect","href":"https://analytics.google.com","as":"script","crossorigin":"anonymous"}],"style":[],"script":[{"src":"https://staticcdn.co.nz/embed/embed.js"}],"noscript":[],"title":"Public Trust","htmlAttrs":{"lang":"en-NZ"},"charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = false

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false