import { defineStore } from 'pinia';
import { usePagesStore } from '~/stores/pages';
import { useLayoutNavStore } from '~/stores/layout-nav';
import { useGlobalsStore } from '~/stores/globals';
import { useSeoStore } from '~/stores/seo';
import { useGrantsSearchStore } from '~/stores/grants-search';
import { useTriageToolStore } from '~/stores/triage-tool';

interface State {
  loaded: boolean;
}

export const useInitStore = defineStore('init', {
  state: (): State => ({
    loaded: false,
  }),

  actions: {
    setData(payload: any) {
      const nav = useLayoutNavStore();
      nav.setNavs(payload.navs || []);

      const pages = usePagesStore();
      pages.setTree(payload.pages_tree || []);

      const seo = useSeoStore();
      seo.setData(payload.seo || {});

      const globals = useGlobalsStore();
      globals.setData(payload.globals || {});

      const grantsSearchStore = useGrantsSearchStore();
      grantsSearchStore.setGrantsInitialData({ data: payload.grants || {} });

      const triageToolStore = useTriageToolStore();
      triageToolStore.setData({ data: payload.triage_tool || {} });

      this.loaded = true;
    },
  },
});
