import { useRuntimeConfig } from '#app';
import { defineStore } from 'pinia';
import type { LooseObject, Nullable } from '~/types/generic';

interface State {
  navs: LooseObject;
  openedNavItem: LooseObject;
  navOverlayOpen: boolean;
}
export const useLayoutNavStore = defineStore('navs', {
  state: (): State => ({
    navs: {},
    openedNavItem: null,
    navOverlayOpen: false,
  }),

  actions: {
    setNav({ nav, menu }: { nav: string; menu: any }) {
      this.navs[nav] = menu;
    },

    setNavs(navs: LooseObject) {
      this.navs = navs;
    },

    toggleOpenedNavItem(item = null) {
      this.openedNavItem = item;
    },

    toggleNavOverlay(force: Nullable<boolean> = null) {
      this.navOverlayOpen = force === null ? !this.navOverlayOpen : force;
    },

    async loadNav(nav: string) {
      if (this.navs[nav]) {
        return Promise.resolve(this.navs[nav]);
      }

      const runtime = useRuntimeConfig();
      const headerName = runtime.generateHeader;
      const headerValue = runtime.generateValue;

      try {
        const menu = await $fetch(`${runtime.public.apiUrl}/api/pt/nav/${nav}`, {
          headers: {
            [headerName]: headerValue,
          },
        });

        this.setNav({ nav, menu });
        return true;
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
});
