<template>
  <div v-if="isDigitalScreenModeEnabled">
    <digital-screen-nav-bar />
    <digital-screen-idle-timeout-popup />
    <digital-screen-external-link-popup />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useDigitalScreenStore } from '~/stores/digital-screen';
import { DIGITAL_SCREEN_IDLE_TIMEOUT_POPUP, DIGITAL_SCREEN_EXTERNAL_LINK_POPUP } from '@/constants/popups';
import { linkOrParentLink, isLinkExternal } from '@/utils/links';
import { usePopupsStore } from '~/stores/popups';
import DigitalScreenNavBar from '~/components/global/components/digital-screen/DigitalScreenNavBar.vue';
import DigitalScreenIdleTimeoutPopup from '~/components/global/components/digital-screen/DigitalScreenIdleTimeoutPopup.vue';
import DigitalScreenExternalLinkPopup from '~/components/global/components/digital-screen/DigitalScreenExternalLinkPopup.vue';

const digitalScreenStore = useDigitalScreenStore();
const { openPopup } = usePopupsStore();

const route = useRoute();

const idleBreakingEvents = ['mousemove', 'keypress', 'touchstart', 'touchmove'];
const idleTimeoutHandle = ref(null);

const isDigitalScreenModeEnabled = computed(() => digitalScreenStore.isDigitalScreenModeEnabled);
const idleTimeoutSec = computed(() => digitalScreenStore.idleTimeoutSec);
const homePath = computed(() => digitalScreenStore.homePath);

const clearIdleTimeout = () => {
  if (idleTimeoutHandle.value) {
    clearTimeout(idleTimeoutHandle.value);
    idleTimeoutHandle.value = null;
  }
};

const setIdleTimeout = () => {
  idleTimeoutHandle.value = setTimeout(() => handleTimeoutTrigger(), idleTimeoutSec.value * 1000);
};

const resetIdleTimeout = () => {
  if (!idleTimeoutHandle.value) {
    return;
  }
  clearIdleTimeout();
  setIdleTimeout();
};

const handleTimeoutTrigger = () => {
  openPopup(DIGITAL_SCREEN_IDLE_TIMEOUT_POPUP);
  clearIdleTimeout();
  setIdleTimeout();
};

const handleExternalLinkTrigger = () => {
  openPopup(DIGITAL_SCREEN_EXTERNAL_LINK_POPUP);
};

const initExternalLinksHandler = () => {
  if (isDigitalScreenModeEnabled.value) {
    document.addEventListener(
      'click',
      (e) => {
        const link = linkOrParentLink(e.target);
        if (!link) {
          return;
        }
        if (link.getAttribute('target') === '_blank' || isLinkExternal(link.getAttribute('href'))) {
          e.preventDefault();
          handleExternalLinkTrigger();
        }
      },
      false
    );
  }
};

const initIdleTimeout = () => {
  idleBreakingEvents.forEach((e) => {
    window.addEventListener(e, resetIdleTimeout);
  });
  if (route.path !== homePath.value) {
    setIdleTimeout();
  }
};

onMounted(() => {
  digitalScreenStore.init();
  initIdleTimeout();
  initExternalLinksHandler();
});

onBeforeUnmount(() => {
  clearIdleTimeout();
  idleBreakingEvents.forEach((e) => {
    window.removeEventListener(e, resetIdleTimeout);
  });
});

watch(route, (to) => {
  if (to.path !== homePath.value) {
    clearIdleTimeout();
    setIdleTimeout();
  } else {
    clearIdleTimeout();
  }
});
</script>
