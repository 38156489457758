import { createGtm } from '@gtm-support/vue-gtm';
import { defineNuxtPlugin, useRouter } from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  if (config.public.gtm.id) {
    nuxtApp.vueApp.use(
      createGtm({
        id: String(config.public.gtm.id),
        enabled: config.public.gtm.enabled,
        debug: config.public.gtm.enabled,
        loadScript: true,
        vueRouter: useRouter(),
        trackOnNextTick: false,
        compatibility: false,
        defer: false,
      })
    );
  }
});
