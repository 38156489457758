import { useNuxtApp } from '#app';
import { defineStore } from 'pinia';
import omit from 'lodash/omit';
import { GRANTS_TYPE_INDIVIDUAL } from '@/constants/grants';
import { getGrantsResultsAlgolia } from '~/utils/algolia';

export const initialSearchParams = {
  page: 0,
  query: '',
  type: GRANTS_TYPE_INDIVIDUAL,
  sector: undefined,
  region: undefined,
  openOnly: undefined,
};

export const useGrantsSearchStore = defineStore('grantsSearch', {
  state: () => ({
    isLoading: false,
    regions: [],
    sectors: [],
    searchParams: {
      ...initialSearchParams,
    },
    lastResult: null,
    hits: [],
  }),
  getters: {
    canLoadMore: (state) => {
      return !state.isLoading && state.lastResult && state.searchParams.page < state.lastResult.nbPages - 1;
    },
  },
  actions: {
    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },
    setSearchParams(searchParams) {
      this.searchParams = {
        ...initialSearchParams,
        ...searchParams,
      };
      this.searchParams.page = this.searchParams.page || 0;
    },
    setGrantsInitialData({ data }) {
      this.regions = data.regions;
      this.sectors = data.sectors;
    },
    setHits({ hits, append }) {
      if (append) {
        this.hits = [...this.hits, ...hits];
      } else {
        this.hits = [...hits];
      }
    },
    setLastResult({ result }) {
      this.lastResult = result;
    },
    reset() {
      this.setSearchParams({});
    },
    async search(searchParams, isLoadMore = false, pushHistory = true, resetPagination = true) {
      const { $search } = useNuxtApp();

      if (resetPagination) {
        searchParams.page = 0;
      }

      if (pushHistory && !isLoadMore) {
        this.router.push({ query: omit(searchParams, ['page']) });
      }
      this.setSearchParams(searchParams);
      this.setIsLoading(true);
      if (!isLoadMore) {
        this.setHits({ hits: [], append: false });
        this.setLastResult({ result: null });
      }
      try {
        const result = await getGrantsResultsAlgolia($search, searchParams);
        this.setLastResult({ result });
        this.setHits({ hits: result.hits, append: isLoadMore });
        return true;
      } catch (e) {
        console.error(e);
      } finally {
        this.setIsLoading(false);
      }
    },
  },
});
