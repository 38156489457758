<template>
  <component :is="tag" class="icon" :class="size ? `is-${size}` : ''">
    <component :is="iconComponentName" class="icon__icon" />
  </component>
</template>

<script lang="ts" setup>
import kebabCase from 'lodash/kebabCase';
import { icons } from '~/components/global/icons/Icons';

const props = defineProps({
  tag: {
    type: String,
    default: 'span',
  },
  size: {
    type: Number,
    default: 32,
  },
  name: {
    type: String,
    required: true,
  },
});

defineOptions({
  components: {
    ...icons,
  },
});

const iconComponentName = `pt-icon-${kebabCase(props.name)}`;
</script>
