<template>
  <component :is="linkComponent" v-bind="linkBindings" class="digital-screen-nav-bar-menu-item">
    {{ item.label }}
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { LooseObject } from '~/types/generic';

const props = defineProps({
  item: {
    type: Object as () => LooseObject,
    required: true,
  },
});

const linkComponent = computed(() => props.item.component || 'app-link');

const linkBindings = computed(() => {
  const bindings = {
    ...props.item.bindings,
  };
  if (bindings.action) {
    bindings.href = '#';
  }
  if (bindings.to) {
    bindings.to = slashed(bindings.to);
  }
  return bindings;
});

const slashed = (path: string) => {
  return path.startsWith('/') ? path : `/${path}`;
};
</script>
