<template>
  <nuxt-layout>
    <template-error :message="message" :status-code="statusCode" />
  </nuxt-layout>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { type NuxtError, useRuntimeConfig } from '#app';
import TemplateError from '~/components/templates/TemplateError.vue';
import { useInitStore } from '~/stores/init';
import type { LooseObject } from '~/types/generic';

const props = defineProps({
  error: {
    type: Object as () => NuxtError,
    default: null,
  },
});

const layout = useLayoutStore();
const statusCode = computed(() => (props.error && props.error.statusCode) || 500);
const message = computed(() => (props.error && props.error.message) || '<%= messages.client_error %>');
const init = useInitStore();

if (!init.loaded) {
  const runtime = useRuntimeConfig();
  const headerName = runtime.generateHeader;
  const headerValue = runtime.generateValue;

  const resp: LooseObject = await $fetch(`${useRuntimeConfig().public.apiUrl}/api/pt/init`, {
    headers: {
      [headerName]: headerValue,
    },
  });
  init.setData(resp.data || {});
}

onMounted(() => {
  layout.setPageLoaded(true);
});
</script>
