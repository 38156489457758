import { defineStore } from 'pinia';
import type { LooseObject } from '~/types/generic';

interface State {
  seo: LooseObject;
}

export const useSeoStore = defineStore('seo', {
  state: (): State => ({
    seo: {},
  }),

  actions: {
    setData(seo) {
      this.seo = seo;
    },
  },
});
