import type { RouteLocation } from 'vue-router';

export function useLivePreview() {
  const livePreviewStore = useLivePreviewStore();

  const isLivePreview = (route: RouteLocation): boolean => {
    return !!(route.query['live-preview'] && route.query.token);
  };

  const getLivePreviewHashFromPath = (route: RouteLocation): string => {
    return isLivePreview(route) ? (route.query.token as string) : null;
  };

  const handleLivePreviewMode = async (hash: string) => {
    if (!hash) {
      return;
    }

    await livePreviewStore.load(hash);
    if (window.localStorage) {
      const preservedScrollY = window.localStorage.getItem(`${hash}-scroll`);
      if (preservedScrollY) {
        window.scrollTo(0, Number(preservedScrollY) || 0);
        setTimeout(() => {
          window.scrollTo(0, Number(preservedScrollY) || 0);
          preserveLivePreviewScroll(hash);
        }, 300);
      }
    }

    window.addEventListener('scroll', () => preserveLivePreviewScroll(hash));
  };

  const preserveLivePreviewScroll = (hash: string) => {
    if (window.localStorage && livePreviewStore.entry) {
      window.localStorage.setItem(`${hash}-scroll`, String(window.scrollY));
    }
  };

  return {
    getLivePreviewHashFromPath,
    isLivePreview,
    handleLivePreviewMode,
  };
}
