import { checkIfIsPasswordProtected } from '~/utils/passwordProtection';

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) return;

  const isPasswordProtected = checkIfIsPasswordProtected(from);
  const shouldNavigate = isPasswordProtected && to.path !== '/password-protected/' && to.path !== '/password-protected';

  if (shouldNavigate) {
    return navigateTo('/password-protected/');
  }
});
