import { defineStore } from 'pinia';
import { PAGE_STATE_PRELOAD } from '~/constants/layout';

export const useLayoutStore = defineStore('layout', {
  state: () => ({
    pageState: PAGE_STATE_PRELOAD,
    pageLoaded: false,
  }),

  actions: {
    setPageState(pageState) {
      this.pageState = pageState;
    },
    setPageLoaded(loaded: boolean) {
      this.pageLoaded = loaded;
    },
  },
});
