import { useCookie, useRuntimeConfig } from '#app';
import { defineStore } from 'pinia';
import { useLivePreviewStore } from '~/stores/live-preview';
import { slashed, unslashed } from '~/utils/url';
import type { LooseObject } from '~/types/generic';

interface DigitalScreenMode extends LooseObject {
  supportedModes: Array<LooseObject>;
  digitalScreenMode: string | null;
  digitalScreenPage: LooseObject;
  idleTimeoutSec: number;
  idlePopupTimeoutSec: number;
}

export const useDigitalScreenStore = defineStore('digitalScreen', {
  state: (): DigitalScreenMode => ({
    supportedModes: [{ title: 'Auckland North West', slug: 'auckland-northwest' }],
    digitalScreenMode: null,
    digitalScreenPage: {},
    idleTimeoutSec: 120,
    idlePopupTimeoutSec: 60,
  }),
  getters: {
    isDigitalScreenModeEnabled: (state) => !!state.digitalScreenMode,
    currentDigitalScreenPage: (state): LooseObject => {
      const livePreviewStore = useLivePreviewStore();

      const livePreviewEntry = livePreviewStore.entry;
      return livePreviewEntry || state.digitalScreenPage || {};
    },
    hasCurrentDigitalScreenPage(): boolean {
      return !!(this.digitalScreenPage && this.digitalScreenPage.id);
    },
    homePath(state): string {
      return `/digital-screen/${state.digitalScreenMode}/`;
    },
    searchPath(): string {
      return '/digital-screen/search/';
    },
    isAtHome(): boolean {
      const currentPage = this.currentDigitalScreenPage as LooseObject;
      return currentPage && slashed(currentPage.url) === slashed(this.homePath);
    },
  },
  actions: {
    init() {
      const cookiesMode = useCookie('pt-digital-screen');
      this.digitalScreenMode = cookiesMode.value;

      if (cookiesMode.value) {
        document.body.classList.add('is-digital-screen-mode');
      }
    },
    setCurrentDigitalScreenPage(page: LooseObject) {
      this.digitalScreenPage = page;
    },
    setDigitalScreenMode(mode: string, password: string) {
      const runtime = useRuntimeConfig();

      if (password !== runtime.public.digitalScreenModeChangePassword) {
        return Promise.reject(new Error('Incorrect password'));
      }

      const expires = new Date();
      const y5Sec = 60 * 60 * 24 * 365 * 5;
      expires.setTime(expires.getTime() + 1000 * y5Sec);
      const cookiesMode = useCookie('pt-digital-screen', {
        maxAge: y5Sec,
        expires,
        path: '/',
      });

      if (mode) {
        cookiesMode.value = mode;
        this.router.push(`/digital-screen/${mode}/`);
      } else {
        cookiesMode.value = '';
        this.router.push('/');
      }

      this.digitalScreenMode = mode;

      return Promise.resolve();
    },
    async getDigitalScreenPage(path: string) {
      const runtime = useRuntimeConfig();
      const headerName = runtime.generateHeader;
      const headerValue = runtime.generateValue;

      const params = param({
        'filter[uri:equals]': unslashed(path),
      });

      const response: LooseObject = await $fetch(`${runtime.public.apiUrl}/api/collections/digital_screen_pages/entries?${params}`, {
        headers: {
          [headerName]: headerValue,
        },
      });

      return response.data && response.data.length ? response.data[0] : null;
    },
  },
});
