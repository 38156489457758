export function detectTouch() {
  if (typeof window !== 'undefined') {
    return Boolean(
      'ontouchstart' in window ||
        window.navigator.maxTouchPoints > 0 ||
        // @ts-expect-error
        window.navigator.msMaxTouchPoints > 0 ||
        // @ts-expect-error
        (window.DocumentTouch && document instanceof DocumentTouch)
    );
  }
}
