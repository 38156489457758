export function throttle(func, delay) {
  let lastCall = 0;
  let timeoutId = null;

  return function (...args) {
    const now = Date.now();

    const invokeFunc = () => {
      lastCall = now;
      func(...args);
    };

    if (now - lastCall >= delay) {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      invokeFunc();
    } else if (!timeoutId) {
      timeoutId = setTimeout(
        () => {
          invokeFunc();
          timeoutId = null;
        },
        delay - (now - lastCall)
      );
    }
  };
}
