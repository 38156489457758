import { usePopupsStore } from '~/stores/popups';

export const usePopups = (popupName: string = '') => {
  const popupStore = usePopupsStore();
  const currentPopup = computed(() => popupStore.currentPopup);
  const popupOpened = computed({
    get() {
      return popupName && currentPopup.value === popupName;
    },
    set(val) {
      if (val && popupName) {
        popupStore.openPopup(popupName);
        return;
      }
      popupStore.closePopup();
    },
  });

  return {
    currentPopup,
    popupOpened,
    openPopup: popupStore.openPopup,
    closePopup: popupStore.closePopup,
    zoomableImagePopupImage: popupStore.zoomableImagePopupImage,
  };
};
