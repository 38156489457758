<template>
  <div class="page">
    <div class="page__inner container">
      <div class="page__main">
        <div class="page__content content">
          <h2>
            {{ message }}
          </h2>
          <template v-if="statusCode === 404">
            <p>The page you were looking for cannot be found.</p>
            <p>&nbsp;</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  message: {
    type: String,
    default: '',
  },
  statusCode: {
    type: Number,
    default: 500,
  },
});

const message = computed(() => props.message);
const statusCode = computed(() => props.statusCode);
</script>
