import { withoutTrailingSlash, withTrailingSlash } from 'ufo';
import isString from 'lodash/isString';
import { isLinkExternal } from '~/utils/links';

const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
const localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/; // eslint-disable-line
const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/; // eslint-disable-line

export function isUrl(string): boolean {
  if (typeof string !== 'string') {
    return false;
  }

  const match = string.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  return localhostDomainRE.test(everythingAfterProtocol) || nonLocalhostDomainRE.test(everythingAfterProtocol);
}

export function param(a): string {
  const s = [];

  const add = function (k, v) {
    v = typeof v === 'function' ? v() : v;
    v = v === null ? '' : v === undefined ? '' : v;
    s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
  };

  const buildParams = function (prefix, obj) {
    let i;
    let len;
    let key;

    if (prefix) {
      if (Array.isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          buildParams(prefix + '[' + (typeof obj[i] === 'object' && obj[i] ? i : '') + ']', obj[i]);
        }
      } else if (String(obj) === '[object Object]') {
        for (key in obj) {
          buildParams(prefix + '[' + key + ']', obj[key]);
        }
      } else {
        add(prefix, obj);
      }
    } else if (Array.isArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        add(obj[i].name, obj[i].value);
      }
    } else {
      for (key in obj) {
        buildParams(key, obj[key]);
      }
    }
    return s;
  };

  return buildParams('', a).join('&');
}

export function getFullUrl(url: string, baseUrl: string, slash = true) {
  if (!isString(url) || isLinkExternal(url)) {
    return url;
  }

  const suffix = url.indexOf('/') === 0 ? url : `/${url}`;
  const fullUrl = `${unslashed(baseUrl)}${suffix}`;

  return slash ? slashed(fullUrl) : unslashed(fullUrl);
}

export function unslashed(url: string) {
  if (!isString(url)) {
    return url;
  }
  return withoutTrailingSlash(url);
}

export function slashed(url: string) {
  if (!isString(url)) {
    return url;
  }
  return isString(url) ? withTrailingSlash(url) : url;
}

export function urlify(url: any) {
  if (!isString(url)) {
    return url;
  }

  return url.startsWith('www') ? `https://${url}` : url;
}
