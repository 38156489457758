import isString from 'lodash/isString';

export function isLinkExternal(url: any) {
  return !!(isString(url) && url.match(/^(https?|tel:|mailto:|www)/));
}

export function isLinkAction(url) {
  return isString(url) && url.startsWith('#');
}

export function isLinkInternal(url) {
  return isString(url) && url.startsWith('/');
}

export function linkOrParentLink(el) {
  if (el.tagName === 'A' && el) {
    return el;
  } else if (el.parentElement) {
    return linkOrParentLink(el.parentElement);
  } else {
    return null;
  }
}
