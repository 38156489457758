import isEmpty from 'lodash/isEmpty';
import AppLink from '~/components/AppLink';

export function transformNavItem(item: any) {
  if (isEmpty(item)) {
    return null;
  }

  if (!item.is_external) {
    return {
      label: item.label,
      component: AppLink,
      bindings: {
        to: item.link_internal,
      },
    };
  }

  const link = item.link_external || '';

  // external link
  if (link.match(/^https?/)) {
    return {
      label: item.label,
      component: 'a',
      bindings: {
        href: link,
        target: '_blank',
        rel: 'nofollow noopener',
      },
    };
  }

  // check if the link starts with # that means we need to fire a spacial event
  if (link.match(/^#/)) {
    return {
      label: item.label,
      component: 'pt-link-action',
      bindings: {
        tag: 'a',
        action: link,
      },
    };
  }

  if (link.match(/(tel:|mailto:)/)) {
    return {
      label: item.label,
      component: 'a',
      bindings: {
        href: link,
      },
    };
  }

  return {
    label: item.label,
    component: AppLink,
    bindings: {
      to: link,
    },
  };
}
