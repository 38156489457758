<template>
  <div
    v-if="isDigitalScreenModeEnabled"
    class="digital-screen-nav-bar"
    :class="{
      'is-menu-open': menuOverlayOpen,
    }"
  >
    <div class="digital-screen-nav-bar__bar">
      <div class="digital-screen-nav-bar__bar-left">
        <pt-button v-if="!isAtHome" outlined wide type="secondary" class="digital-screen-nav-bar__bar-back-cta" @click="navigateBack">
          <pt-icon name="chevron-right" :size="20" />
          Back
        </pt-button>
        <pt-button v-if="!isAtHome" outlined wide type="secondary" tag="app-link" :to="homePath" @click="onToggleMenuOverlay({ force: false })">
          <pt-icon name="home" :size="20" />
          Home
        </pt-button>
      </div>
      <div class="digital-screen-nav-bar__bar-right">
        <pt-button
          v-if="hasCurrentDigitalScreenPage"
          outlined
          wide
          type="secondary"
          tag="app-link"
          to="/"
          @click="onToggleMenuOverlay({ force: false })"
        >
          <pt-icon name="earth" :size="20" />
          Access website
        </pt-button>
        <pt-button outlined wide type="secondary" tag="app-link" :to="searchPath" @click="onToggleMenuOverlay({ force: false })">
          <pt-icon name="search" :size="20" />
          Search
        </pt-button>
        <pt-button :outlined="!menuOverlayOpen" wide type="secondary" @click="onToggleMenuOverlay({})">
          <pt-icon v-if="menuOverlayOpen" name="close-bold" :size="18" aria-hidden="false" />
          <pt-icon v-else name="hamburger" :size="20" aria-hidden="false" />
          Menu
        </pt-button>
        <logo tag="app-link" to="/" class="digital-screen-nav-bar__logo" aria-label="Public Trust Home" />
      </div>
    </div>
    <div class="digital-screen-nav-bar__menu">
      <button class="digital-screen-nav-bar__menu-close" @click="onToggleMenuOverlay({})">
        <pt-icon name="chevron-down" :size="28" />
      </button>
      <digital-screen-nav-bar-menu-item v-for="(item, index) in navs" :key="`nav-item-${index}`" :item="item" @click="onToggleMenuOverlay({})" />
    </div>
    <div class="digital-screen-nav-bar__menu-bg" @click="onToggleMenuOverlay({})" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Logo from '@/components/global/elements/Logo.vue';
import { transformNavItem } from '@/utils/navs';
import PtButton from '~/components/global/elements/PtButton.vue';
import PtIcon from '~/components/global/elements/PtIcon.vue';
import DigitalScreenNavBarMenuItem from '~/components/global/components/digital-screen/DigitalScreenNavBarMenuItem.vue';

const navs = shallowRef([]);
const router = useRouter();

const digitalScreenStore = useDigitalScreenStore();
const digitalScreenNavStore = useDigitalScreenNavStore();
const { loadNav } = useLayoutNavStore();

const digitalScreenMode = computed(() => digitalScreenStore.digitalScreenMode);
const menuOverlayOpen = computed(() => digitalScreenNavStore.menuOverlayOpen);
const isDigitalScreenModeEnabled = computed(() => digitalScreenStore.isDigitalScreenModeEnabled);
const homePath = computed(() => digitalScreenStore.homePath);
const searchPath = computed(() => digitalScreenStore.searchPath);
const isAtHome = computed(() => digitalScreenStore.isAtHome);
const hasCurrentDigitalScreenPage = computed(() => digitalScreenStore.hasCurrentDigitalScreenPage);
const menuNav = computed(() => digitalScreenNavStore.menuNav);

onMounted(async () => {
  await loadNav(`digital_screen_${digitalScreenMode.value.replace(/-/g, '_')}_menu`);
  navs.value = Array.isArray(menuNav.value) ? menuNav.value.map(transformNavItem).filter((x) => x) : [];
});

const onToggleMenuOverlay = (options) => {
  digitalScreenNavStore.toggleMenuOverlay(options);
};

const navigateBack = () => {
  onToggleMenuOverlay({ force: false });
  router.go(-1);
};
</script>
