const PtIconAnalyticsBars = defineAsyncComponent(() => import('./PtIconAnalyticsBars.vue'));
const PtIconAnalyticsPie2 = defineAsyncComponent(() => import('./PtIconAnalyticsPie2.vue'));
const PtIconArrowSelect = defineAsyncComponent(() => import('./PtIconArrowSelect.vue'));
const PtIconAttachment = defineAsyncComponent(() => import('./PtIconAttachment.vue'));
const PtIconAwardRibbonStar1 = defineAsyncComponent(() => import('./PtIconAwardRibbonStar1.vue'));
const PtIconAwardWallStar = defineAsyncComponent(() => import('./PtIconAwardWallStar.vue'));
const PtIconBuildingHouse = defineAsyncComponent(() => import('./PtIconBuildingHouse.vue'));
const PtIconCamera = defineAsyncComponent(() => import('./PtIconCamera.vue'));
const PtIconCheck = defineAsyncComponent(() => import('./PtIconCheck.vue'));
const PtIconCheckboxOff = defineAsyncComponent(() => import('./PtIconCheckboxOff.vue'));
const PtIconCheckboxOn = defineAsyncComponent(() => import('./PtIconCheckboxOn.vue'));
const PtIconCheckCircle = defineAsyncComponent(() => import('./PtIconCheckCircle.vue'));
const PtIconCheckCircle1 = defineAsyncComponent(() => import('./PtIconCheckCircle1.vue'));
const PtIconChevronDown = defineAsyncComponent(() => import('./PtIconChevronDown.vue'));
const PtIconChevronRight = defineAsyncComponent(() => import('./PtIconChevronRight.vue'));
const PtIconChristmasStar = defineAsyncComponent(() => import('./PtIconChristmasStar.vue'));
const PtIconClose = defineAsyncComponent(() => import('./PtIconClose.vue'));
const PtIconCloseBold = defineAsyncComponent(() => import('./PtIconCloseBold.vue'));
const PtIconDatingFlowersVase = defineAsyncComponent(() => import('./PtIconDatingFlowersVase.vue'));
const PtIconDeathCoffin = defineAsyncComponent(() => import('./PtIconDeathCoffin.vue'));
const PtIconDeathRip = defineAsyncComponent(() => import('./PtIconDeathRip.vue'));
const PtIconDietHealth = defineAsyncComponent(() => import('./PtIconDietHealth.vue'));
const PtIconDisabilityBlindReadFinger = defineAsyncComponent(() => import('./PtIconDisabilityBlindReadFinger.vue'));
const PtIconDisabilityHearingAid = defineAsyncComponent(() => import('./PtIconDisabilityHearingAid.vue'));
const PtIconDisabilityInformation = defineAsyncComponent(() => import('./PtIconDisabilityInformation.vue'));
const PtIconDisabilityWalkingHelp = defineAsyncComponent(() => import('./PtIconDisabilityWalkingHelp.vue'));
const PtIconDisabilityWheelchair = defineAsyncComponent(() => import('./PtIconDisabilityWheelchair.vue'));
const PtIconDisabilityWheelchair1 = defineAsyncComponent(() => import('./PtIconDisabilityWheelchair1.vue'));
const PtIconDownload = defineAsyncComponent(() => import('./PtIconDownload.vue'));
const PtIconEmailActionUnread = defineAsyncComponent(() => import('./PtIconEmailActionUnread.vue'));
const PtIconFacebook = defineAsyncComponent(() => import('./PtIconFacebook.vue'));
const PtIconFamilyGrandfather = defineAsyncComponent(() => import('./PtIconFamilyGrandfather.vue'));
const PtIconFamilyGrandmother = defineAsyncComponent(() => import('./PtIconFamilyGrandmother.vue'));
const PtIconFamilyHeart = defineAsyncComponent(() => import('./PtIconFamilyHeart.vue'));
const PtIconFamilyHome1 = defineAsyncComponent(() => import('./PtIconFamilyHome1.vue'));
const PtIconFamilyMother = defineAsyncComponent(() => import('./PtIconFamilyMother.vue'));
const PtIconFamilyUmbrellaProtect = defineAsyncComponent(() => import('./PtIconFamilyUmbrellaProtect.vue'));
const PtIconAccordionToggle = defineAsyncComponent(() => import('./PtIconAccordionToggle.vue'));
const PtIconGiftBox1 = defineAsyncComponent(() => import('./PtIconGiftBox1.vue'));
const PtIconHamburger = defineAsyncComponent(() => import('./PtIconHamburger.vue'));
const PtIconHeadphonesCustomerSupportHuman1 = defineAsyncComponent(() => import('./PtIconHeadphonesCustomerSupportHuman1.vue'));
const PtIconLaptopHelpMessage = defineAsyncComponent(() => import('./PtIconLaptopHelpMessage.vue'));
const PtIconLegalJudgeBalance = defineAsyncComponent(() => import('./PtIconLegalJudgeBalance.vue'));
const PtIconLinkedin = defineAsyncComponent(() => import('./PtIconLinkedin.vue'));
const PtIconListAdd = defineAsyncComponent(() => import('./PtIconListAdd.vue'));
const PtIconListBullets1 = defineAsyncComponent(() => import('./PtIconListBullets1.vue'));
const PtIconListStars = defineAsyncComponent(() => import('./PtIconListStars.vue'));
const PtIconLoveBird = defineAsyncComponent(() => import('./PtIconLoveBird.vue'));
const PtIconLoveHeartHandsHold = defineAsyncComponent(() => import('./PtIconLoveHeartHandsHold.vue'));
const PtIconLoveItRibbon2 = defineAsyncComponent(() => import('./PtIconLoveItRibbon2.vue'));
const PtIconLoveShield = defineAsyncComponent(() => import('./PtIconLoveShield.vue'));
const PtIconMail = defineAsyncComponent(() => import('./PtIconMail.vue'));
const PtIconMapsPin1 = defineAsyncComponent(() => import('./PtIconMapsPin1.vue'));
const PtIconMedicalRoomWait = defineAsyncComponent(() => import('./PtIconMedicalRoomWait.vue'));
const PtIconMenuDrop = defineAsyncComponent(() => import('./PtIconMenuDrop.vue'));
const PtIconMessagesPeopleManBubbleCircle1 = defineAsyncComponent(() => import('./PtIconMessagesPeopleManBubbleCircle1.vue'));
const PtIconMoneyBagDollar = defineAsyncComponent(() => import('./PtIconMoneyBagDollar.vue'));
const PtIconMoneyBags = defineAsyncComponent(() => import('./PtIconMoneyBags.vue'));
const PtIconMoneyWallet1 = defineAsyncComponent(() => import('./PtIconMoneyWallet1.vue'));
const PtIconOpenLabel = defineAsyncComponent(() => import('./PtIconOpenLabel.vue'));
const PtIconOpenQuote = defineAsyncComponent(() => import('./PtIconOpenQuote.vue'));
const PtIconOrganicPlantGrow = defineAsyncComponent(() => import('./PtIconOrganicPlantGrow.vue'));
const PtIconPerformanceMoneyIncrease = defineAsyncComponent(() => import('./PtIconPerformanceMoneyIncrease.vue'));
const PtIconPhone = defineAsyncComponent(() => import('./PtIconPhone.vue'));
const PtIconPhoneAlt = defineAsyncComponent(() => import('./PtIconPhoneAlt.vue'));
const PtIconProfessionalNetworkLinkedin = defineAsyncComponent(() => import('./PtIconProfessionalNetworkLinkedin.vue'));
const PtIconRealEstatePersonSearchHouse = defineAsyncComponent(() => import('./PtIconRealEstatePersonSearchHouse.vue'));
const PtIconReligionAngel1 = defineAsyncComponent(() => import('./PtIconReligionAngel1.vue'));
const PtIconSavingMoneyFlower = defineAsyncComponent(() => import('./PtIconSavingMoneyFlower.vue'));
const PtIconSavingMoneySeedling = defineAsyncComponent(() => import('./PtIconSavingMoneySeedling.vue'));
const PtIconSearch = defineAsyncComponent(() => import('./PtIconSearch.vue'));
const PtIconSearchAlternate = defineAsyncComponent(() => import('./PtIconSearchAlternate.vue'));
const PtIconSearchBold = defineAsyncComponent(() => import('./PtIconSearchBold.vue'));
const PtIconSearchCircle = defineAsyncComponent(() => import('./PtIconSearchCircle.vue'));
const PtIconSendEmail1 = defineAsyncComponent(() => import('./PtIconSendEmail1.vue'));
const PtIconShopSignOpen = defineAsyncComponent(() => import('./PtIconShopSignOpen.vue'));
const PtIconSingleManActionsMoney = defineAsyncComponent(() => import('./PtIconSingleManActionsMoney.vue'));
const PtIconSmileyHappy = defineAsyncComponent(() => import('./PtIconSmileyHappy.vue'));
const PtIconSocialMediaFacebook = defineAsyncComponent(() => import('./PtIconSocialMediaFacebook.vue'));
const PtIconSocialMediaTwitter = defineAsyncComponent(() => import('./PtIconSocialMediaTwitter.vue'));
const PtIconSocialVideoYoutubeClip = defineAsyncComponent(() => import('./PtIconSocialVideoYoutubeClip.vue'));
const PtIconStyleTwoPinWorld = defineAsyncComponent(() => import('./PtIconStyleTwoPinWorld.vue'));
const PtIconTaxiWaveBusinessman = defineAsyncComponent(() => import('./PtIconTaxiWaveBusinessman.vue'));
const PtIconTeamMeetingMessageQuestion1 = defineAsyncComponent(() => import('./PtIconTeamMeetingMessageQuestion1.vue'));
const PtIconTimeClockCircle2 = defineAsyncComponent(() => import('./PtIconTimeClockCircle2.vue'));
const PtIconTimeStopwatch3Quarters = defineAsyncComponent(() => import('./PtIconTimeStopwatch3Quarters.vue'));
const PtIconToolsHammer2 = defineAsyncComponent(() => import('./PtIconToolsHammer2.vue'));
const PtIconTravelMapFinger = defineAsyncComponent(() => import('./PtIconTravelMapFinger.vue'));
const PtIconTreasureChestOpen = defineAsyncComponent(() => import('./PtIconTreasureChestOpen.vue'));
const PtIconWatchTime = defineAsyncComponent(() => import('./PtIconWatchTime.vue'));
const PtIconWeddingMoneyPiggy = defineAsyncComponent(() => import('./PtIconWeddingMoneyPiggy.vue'));
const PtIconWildBird1 = defineAsyncComponent(() => import('./PtIconWildBird1.vue'));
const PtIconYoutube = defineAsyncComponent(() => import('./PtIconYoutube.vue'));
const PtIconAlertDiamond = defineAsyncComponent(() => import('./PtIconAlertDiamond.vue'));
const PtIconTeamMeeting = defineAsyncComponent(() => import('./PtIconTeamMeeting.vue'));
const PtIconAirplane = defineAsyncComponent(() => import('./PtIconAirplane.vue'));
const PtIconFeedback = defineAsyncComponent(() => import('./PtIconFeedback.vue'));
const PtIconPin = defineAsyncComponent(() => import('./PtIconPin.vue'));
const PtIconCalendar3 = defineAsyncComponent(() => import('./PtIconCalendar3.vue'));
const PtIconHome = defineAsyncComponent(() => import('./PtIconHome.vue'));
const PtIconPrint = defineAsyncComponent(() => import('./PtIconPrint.vue'));
const PtIconEarth = defineAsyncComponent(() => import('./PtIconEarth.vue'));

export const icons = {
  PtIconAnalyticsBars,
  PtIconAnalyticsPie2,
  PtIconArrowSelect,
  PtIconAttachment,
  PtIconAwardRibbonStar1,
  PtIconAwardWallStar,
  PtIconBuildingHouse,
  PtIconCamera,
  PtIconCheck,
  PtIconCheckboxOff,
  PtIconCheckboxOn,
  PtIconCheckCircle,
  PtIconCheckCircle1,
  PtIconChevronDown,
  PtIconChevronRight,
  PtIconChristmasStar,
  PtIconClose,
  PtIconCloseBold,
  PtIconDatingFlowersVase,
  PtIconDeathCoffin,
  PtIconDeathRip,
  PtIconDietHealth,
  PtIconDisabilityBlindReadFinger,
  PtIconDisabilityHearingAid,
  PtIconDisabilityInformation,
  PtIconDisabilityWalkingHelp,
  PtIconDisabilityWheelchair,
  PtIconDisabilityWheelchair1,
  PtIconDownload,
  PtIconEmailActionUnread,
  PtIconFacebook,
  PtIconFamilyGrandfather,
  PtIconFamilyGrandmother,
  PtIconFamilyHeart,
  PtIconFamilyHome1,
  PtIconFamilyMother,
  PtIconFamilyUmbrellaProtect,
  PtIconAccordionToggle,
  PtIconGiftBox1,
  PtIconHamburger,
  PtIconHeadphonesCustomerSupportHuman1,
  PtIconLaptopHelpMessage,
  PtIconLegalJudgeBalance,
  PtIconLinkedin,
  PtIconListAdd,
  PtIconListBullets1,
  PtIconListStars,
  PtIconLoveBird,
  PtIconLoveHeartHandsHold,
  PtIconLoveItRibbon2,
  PtIconLoveShield,
  PtIconMail,
  PtIconMapsPin1,
  PtIconMedicalRoomWait,
  PtIconMenuDrop,
  PtIconMessagesPeopleManBubbleCircle1,
  PtIconMoneyBagDollar,
  PtIconMoneyBags,
  PtIconMoneyWallet1,
  PtIconOpenLabel,
  PtIconOpenQuote,
  PtIconOrganicPlantGrow,
  PtIconPerformanceMoneyIncrease,
  PtIconPhone,
  PtIconPhoneAlt,
  PtIconProfessionalNetworkLinkedin,
  PtIconRealEstatePersonSearchHouse,
  PtIconReligionAngel1,
  PtIconSavingMoneyFlower,
  PtIconSavingMoneySeedling,
  PtIconSearch,
  PtIconSearchAlternate,
  PtIconSearchBold,
  PtIconSearchCircle,
  PtIconSendEmail1,
  PtIconShopSignOpen,
  PtIconSingleManActionsMoney,
  PtIconSmileyHappy,
  PtIconSocialMediaFacebook,
  PtIconSocialMediaTwitter,
  PtIconSocialVideoYoutubeClip,
  PtIconStyleTwoPinWorld,
  PtIconTaxiWaveBusinessman,
  PtIconTeamMeetingMessageQuestion1,
  PtIconTimeClockCircle2,
  PtIconTimeStopwatch3Quarters,
  PtIconToolsHammer2,
  PtIconTravelMapFinger,
  PtIconTreasureChestOpen,
  PtIconWatchTime,
  PtIconWeddingMoneyPiggy,
  PtIconWildBird1,
  PtIconYoutube,
  PtIconAlertDiamond,
  PtIconTeamMeeting,
  PtIconAirplane,
  PtIconFeedback,
  PtIconPin,
  PtIconCalendar3,
  PtIconHome,
  PtIconPrint,
  PtIconEarth,
};
