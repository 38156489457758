export const isObject = (obj): boolean => {
  const type = typeof obj;
  return type === 'function' || (type === 'object' && !!obj);
};

export const get = (obj, path, defaultValue = undefined) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

export const invert = (obj: any) => {
  const result: { [key: string]: any } = {};
  for (const [key, value] of Object.entries(obj)) {
    result[value as any] = key;
  }
  return result;
};
