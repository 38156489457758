import { defineNuxtRouteMiddleware, navigateTo } from '#app';
import { useLivePreviewStore } from '~/stores/live-preview';

export default defineNuxtRouteMiddleware((to, from) => {
  const livePreviewStore = useLivePreviewStore();

  // Block routing in live preview mode
  if (livePreviewStore.entry && from.path !== to.path) {
    return navigateTo(from.fullPath);
  }
});
