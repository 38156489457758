import { defineStore } from 'pinia';
import { useDigitalScreenStore } from '~/stores/digital-screen';

const RESULTS = {
  NO_OPTIONS: [],
  IN_PERSON: ['in-centre-will'],
  COMPREHENSIVE_OR_IN_PERSON: ['comprehensive-online-will', 'in-centre-will'],
  STANDART_OR_IN_PERSON: ['standard-online-will', 'in-centre-will'],
  BASIC_OR_IN_PERSON: ['basic-online-will', 'in-centre-will'],
};

const questions = [
  {
    id: 0,
    title: 'Do you have assets in New Zealand?',
    description:
      'You can be overseas, however it can just be a little bit more complicated when it comes to the time the will needs to be proven (in probate).',
    answers: [
      {
        title: 'No',
        result: RESULTS.NO_OPTIONS,
      },
      {
        title: 'Yes',
        next: 1,
      },
    ],
  },
  {
    id: 1,
    title: 'Are you comfortable doing your will yourself online?',
    description: 'You’ll be supported by live chat for your general queries and can purchase an expert review at any time to give you peace of mind.',
    answers: [
      {
        title: 'No',
        result: RESULTS.IN_PERSON,
      },
      {
        title: 'Yes',
        next: 2,
      },
    ],
  },
  {
    id: 2,
    title: 'Do you have any children?',
    description: 'This includes all scenarios e.g. shared custody, blended families etc.',
    answers: [
      {
        title: 'No',
        next: 4,
      },
      {
        title: 'Yes',
        next: 3,
      },
    ],
  },
  {
    id: 3,
    title: 'Do you have a blended family?',
    description: 'This is where you or your partner may have children from previous relationships.',
    answers: [
      {
        title: 'No',
        next: 4,
      },
      {
        title: 'Yes',
        result: RESULTS.COMPREHENSIVE_OR_IN_PERSON,
      },
    ],
  },
  {
    id: 4,
    title: 'Would you like to include specific information on separation or divorce?',
    description: 'This can include previous relationships, or just to be prepared for all future scenarios.',
    answers: [
      {
        title: 'No',
        next: 5,
      },
      {
        title: 'Yes',
        result: RESULTS.COMPREHENSIVE_OR_IN_PERSON,
      },
    ],
  },
  {
    id: 5,
    title: 'Do you need to include a Family Trust or a Business in your will?',
    answers: [
      {
        title: 'No',
        next: 6,
      },
      {
        title: 'Yes',
        result: RESULTS.COMPREHENSIVE_OR_IN_PERSON,
      },
    ],
  },
  {
    id: 6,
    title: 'Would you like to gift specific items to people?',
    description: 'This could be as small as a ring, as big as a car or as valuable as a pet.',
    answers: [
      {
        title: 'No',
        next: 7,
      },
      {
        title: 'Yes',
        result: RESULTS.STANDART_OR_IN_PERSON,
      },
    ],
  },
  {
    id: 7,
    title: 'Are you in a relationship?',
    answers: [
      {
        title: 'No',
        result: RESULTS.BASIC_OR_IN_PERSON,
      },
      {
        title: 'Yes',
        next: 8,
      },
    ],
  },
  {
    id: 8,
    title: 'Do you also need a will for your spouse/partner at the same time?',
    description: 'This is referred to as a “Mirror Will” where you have similar intentions.',
    answers: [
      {
        title: 'No',
        result: RESULTS.STANDART_OR_IN_PERSON,
      },
      {
        title: 'Yes',
        result: RESULTS.STANDART_OR_IN_PERSON,
      },
    ],
  },
];

export const useTriageToolStore = defineStore('triageTool', {
  state: () => ({
    questionsPath: [0],
    result: null,
    questions,
    willsPricingList: [],
    digitalScreenViewAllLink: null,
    websiteViewAllLink: null,
  }),
  getters: {
    currentQuestion(state) {
      const lastQuestionId = state.questionsPath[state.questionsPath.length - 1];
      return state.questions.find((q) => q.id === lastQuestionId);
    },
    viewAllLink(state) {
      const digitalScreenStore = useDigitalScreenStore();
      return digitalScreenStore.digitalScreenMode ? state.digitalScreenViewAllLink : state.websiteViewAllLink;
    },
    hasResult(state): boolean {
      return state.result !== null;
    },
    hasPrevQuestion(state): boolean {
      return state.questionsPath.length > 1 || this.hasResult;
    },
    hasResultOptions(state) {
      return state.result?.length > 0;
    },
  },
  actions: {
    setData({ data }) {
      this.willsPricingList = data.wills_pricing_items;
      this.digitalScreenViewAllLink = data.digital_screen_view_all_link;
      this.websiteViewAllLink = data.website_view_all_link;
    },
    setResult(resultId = null) {
      this.result = resultId;
    },
    prevQuestion() {
      if (!this.result) {
        this.questionsPath.pop();
      } else {
        this.result = null;
      }
    },
    nextQuestion(questionId = 0) {
      this.questionsPath.push(questionId);
    },
    reset() {
      this.questionsPath = [0];
      this.result = null;
    },
  },
});
