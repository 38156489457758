
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93JsH2l1VMOfMeta } from "/opt/build/repo/pages/digital-screen/[...slug].vue?macro=true";
import { default as indexRunZAcWrPxMeta } from "/opt/build/repo/pages/digital-screen/mode/index.vue?macro=true";
import { default as _91slug_93miPI3yx3KWMeta } from "/opt/build/repo/pages/digital-screen/pricing-item/[slug].vue?macro=true";
import { default as _91slug_93gRUDwcACZUMeta } from "/opt/build/repo/pages/grants/[slug].vue?macro=true";
import { default as indexvB327eh3JRMeta } from "/opt/build/repo/pages/grants/index.vue?macro=true";
import { default as indexwEZuywhPJPMeta } from "/opt/build/repo/pages/password-protected/index.vue?macro=true";
import { default as _91slug_93xJNWdmQIqbMeta } from "/opt/build/repo/pages/resources/[slug].vue?macro=true";
import { default as indexy6tMz8ve5gMeta } from "/opt/build/repo/pages/resources/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93EhWyQvnGPmMeta || {},
    component: () => import("/opt/build/repo/pages/[...slug].vue")
  },
  {
    name: "digital-screen-slug",
    path: "/digital-screen/:slug(.*)*",
    meta: _91_46_46_46slug_93JsH2l1VMOfMeta || {},
    component: () => import("/opt/build/repo/pages/digital-screen/[...slug].vue")
  },
  {
    name: "digital-screen-mode",
    path: "/digital-screen/mode",
    meta: indexRunZAcWrPxMeta || {},
    component: () => import("/opt/build/repo/pages/digital-screen/mode/index.vue")
  },
  {
    name: "digital-screen-pricing-item-slug",
    path: "/digital-screen/pricing-item/:slug()",
    meta: _91slug_93miPI3yx3KWMeta || {},
    component: () => import("/opt/build/repo/pages/digital-screen/pricing-item/[slug].vue")
  },
  {
    name: "grants-slug",
    path: "/grants/:slug()",
    meta: _91slug_93gRUDwcACZUMeta || {},
    component: () => import("/opt/build/repo/pages/grants/[slug].vue")
  },
  {
    name: "grants",
    path: "/grants",
    meta: indexvB327eh3JRMeta || {},
    component: () => import("/opt/build/repo/pages/grants/index.vue")
  },
  {
    name: "password-protected",
    path: "/password-protected",
    meta: indexwEZuywhPJPMeta || {},
    component: () => import("/opt/build/repo/pages/password-protected/index.vue")
  },
  {
    name: "resources-slug",
    path: "/resources/:slug()",
    meta: _91slug_93xJNWdmQIqbMeta || {},
    component: () => import("/opt/build/repo/pages/resources/[slug].vue")
  },
  {
    name: "resources",
    path: "/resources",
    component: () => import("/opt/build/repo/pages/resources/index.vue")
  }
]