<template>
  <component :is="props.tag" @click.prevent="onClick">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { CONTACT_POPUP_SLUG, TRIAGE_POPUP_SLUG } from '@/constants/popups';
import { usePopupsStore } from '~/stores/popups';

const props = defineProps({
  tag: {
    type: String,
    default: 'a',
  },
  action: {
    type: String,
    required: true,
  },
  onAction: {
    type: Function,
    default: null,
  },
  useDefaultActions: {
    type: Boolean,
    default: true,
  },
});

const popups = usePopupsStore();

const emit = defineEmits(['click']);

const filteredAction = computed(() => props.action.replace('#', ''));

const onClick = (ev) => {
  if (props.useDefaultActions) {
    onActionInternal();
  }
  if (props.onAction) {
    props.onAction(filteredAction.value, ev);
  }
  emit('click', filteredAction.value, ev);
};

const onActionInternal = () => {
  switch (filteredAction.value) {
    case 'book':
      handleBookAction();
      break;
    case 'contact':
      handleContactAction();
      break;
    case 'triage':
      handleTriageAction();
      break;
    default:
      break;
  }
};

const handleBookAction = () => {
  window.location.href = '/locations/';
  // popups.openPopup(BOOK_APPOINTMENT_POPUP_SLUG);
};

const handleTriageAction = () => {
  popups.openPopup(TRIAGE_POPUP_SLUG);
};

const handleContactAction = () => {
  popups.openPopup(CONTACT_POPUP_SLUG);
};
</script>
