import { defineStore } from 'pinia';
import { useDigitalScreenStore } from './digital-screen';
import { useLayoutNavStore } from '~/stores/layout-nav';

export const useDigitalScreenNavStore = defineStore('digitalScreenNav', {
  state: () => ({
    menuOverlayOpen: false,
  }),
  getters: {
    menuNav: () => {
      const digitalScreenStore = useDigitalScreenStore();
      const layoutNavStore = useLayoutNavStore();
      const digitalScreenMode = digitalScreenStore.digitalScreenMode;
      const navs = computed(() => layoutNavStore.navs).value;

      return (digitalScreenMode && navs && navs[`digital_screen_${digitalScreenMode.replace(/-/g, '_')}_menu`]) || [];
    },
  },
  actions: {
    toggleMenuOverlay(p) {
      this.menuOverlayOpen = p && p.force !== undefined ? p.force : !this.menuOverlayOpen;
    },
  },
});
