export const PAGE_STATE_PRELOAD = 'preload';

export const BREAKPOINTS = {
  widescreen: 1268,
  desktop: 1064,
  tablet: 769,
  mobile: 375,
  mobileLegacy: 340,
};

export const DIGITAL_SCREEN_LAYOUT = 'digital-screen';
export const PASSWORD_PROTECTED_LAYOUT = 'password-protected';
export const DEFAULT_LAYOUT = 'default';
