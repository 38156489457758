import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#app';
import type { LooseObject } from '~/types/generic';
import { useLivePreviewStore } from '~/stores/live-preview';
import { param } from '~/utils/url';

interface Grant extends LooseObject {
  id: string;
  title: string;
  url?: string;
  redirect?: string;
  blueprint: {
    handle: string;
  };
  seo?: Array<LooseObject>;
}

interface State {
  isLoading: boolean;
  currentGrant: Grant | null;
}

interface GrantResponse extends LooseObject {
  data: Grant[];
}

export const useGrantsStore = defineStore('grant', {
  state: (): State => ({
    isLoading: false,
    currentGrant: null,
  }),

  getters: {
    getCurrentGrant: (state: State) => {
      const livePreview = useLivePreviewStore();
      const livePreviewEntry = livePreview.entry;
      return livePreviewEntry || state.currentGrant || {};
    },

    hasCurrentGrant: (state: State) => {
      return !!(state.currentGrant && state.currentGrant.id);
    },
  },

  actions: {
    setIsLoading(isLoading: boolean) {
      this.isLoading = isLoading;
    },

    setCurrentGrant(grant = null) {
      this.currentGrant = grant;
    },

    async loadSingle(slug: string) {
      if (this.hasCurrentGrant) {
        return this.currentGrant;
      }

      this.isLoading = true;

      try {
        const params = param({
          'filter[slug:equals]': slug,
        });
        const runtime = useRuntimeConfig();
        const headerName = runtime.generateHeader;
        const headerValue = runtime.generateValue;
        const response: GrantResponse = await $fetch(`${useRuntimeConfig().public.apiUrl}/api/collections/grants/entries?${params}`, {
          headers: {
            [headerName]: headerValue,
          },
        });
        const grant = response.data && response.data.length ? response.data[0] : null;
        if (grant) {
          this.currentGrant = grant;
        }
        return grant || null;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        this.isLoading = false;
      }
    },

    setGrant(grant: Grant) {
      this.currentGrant = grant;
    },
  },
});
