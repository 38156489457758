import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import live_45preview_45global from "/opt/build/repo/middleware/live-preview.global.ts";
import password_45protected_45global from "/opt/build/repo/middleware/password-protected.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  live_45preview_45global,
  password_45protected_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}