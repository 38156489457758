import type { RouterConfig } from '@nuxt/schema';

export default {
  scrollBehavior(to, _, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      if (import.meta.client) {
        const element = document.querySelector(to.hash);
        if (element) {
          return { el: to.hash, behavior: 'smooth' };
        } else {
          // ...
        }
      }
      return { top: 0 };
    } else {
      return { top: 0 };
    }
  },
} as RouterConfig;
