import type { MetaObject } from '@nuxt/schema';
import isString from 'lodash/isString';
import { get } from '~/utils/objects';
import { getFullUrl } from '~/utils/url';

export function generateMeta({ seo, url, baseUrl }) {
  const meta = {
    title: seo.title,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: seo.description,
      },
    ],
    link: [],
  };

  if (url) {
    meta.link.push({
      rel: 'canonical',
      href: getFullUrl(url, baseUrl),
    });
  }

  if (!seo.robots && Array.isArray(seo.robots)) {
    meta.meta.push({
      hid: 'robots',
      name: 'robots',
      content: seo.robots.join(', '),
    });
  }

  ['title', 'type', 'description'].forEach((key) => {
    meta.meta.push({
      hid: `og:${key}`,
      name: `og:${key}`,
      content: seo.og[key] || '',
    });
  });

  if (seo.og.image && seo.og.image.url) {
    meta.meta.push({
      hid: 'og:image',
      name: 'og:image',
      content: seo.og.image.url,
    });
    if (seo.og.image.width) {
      meta.meta.push({
        hid: 'og:image:width',
        name: 'og:image:width',
        content: seo.og.image.width,
      });
    }
    if (seo.og.image.height) {
      meta.meta.push({
        hid: 'og:image:height',
        name: 'og:image:height',
        content: seo.og.image.height,
      });
    }
  }

  return meta;
}

export function generatePageMeta({ page = null, ogType = 'article', globals = {}, additionalMeta = [], route, baseUrl }) {
  if (!page) {
    return {
      title: 'Public Trust',
      meta: [],
    };
  }

  const seo = {
    ...(page.seo || {}),
  };

  const disableSiteName = seo.disable_site_name || false;
  const siteName = get(globals, 'site_name', '');
  const siteNameSeparator = get(globals, 'site_name_separator', '-');
  const titleTemplate = siteName ? `%s ${siteNameSeparator} ${siteName}` : '';

  if (!seo.title) {
    seo.title = page.title;
  }
  if (!seo.description && isString(page.description)) {
    seo.description = page.description;
  }

  if (!seo.og) {
    seo.og = {};
  }
  seo.og = {
    title: seo.title,
    description: seo.description,
    image: seo.image,
    type: ogType,
    ...seo.og,
  };
  const meta: MetaObject = generateMeta({ seo, url: route?.fullPath, baseUrl });

  meta.titleTemplate = disableSiteName ? '%s' : titleTemplate;

  if (additionalMeta.length) {
    meta.meta = [].concat(meta.meta).concat(additionalMeta);
  }

  return meta;
}

export function generatePageLdJson({ page, socialLinks = [], jsonld = {} }) {
  if (!page) {
    return {};
  }

  const name = get(jsonld, 'organization_name', 'Public Trust');
  const url = get(jsonld, 'organization_url', 'https://www.publictrust.co.nz/');
  const legalName = get(jsonld, 'organization_legal_name', 'Public Trust');
  const foundingDate = get(jsonld, 'organization_founding_date');
  const logo = get(jsonld, 'organization_logo');
  const description = page.seo && page.seo.description;

  const contactPoint = get(jsonld, 'contact_points', []).map((point) => ({
    '@type': 'ContactPoint',
    contactType: point.contact_type,
    telephone: point.telephone,
    email: point.email,
  }));

  const founders = get(jsonld, 'founder', []).map((point) => ({
    '@type': 'Person',
    name: point.name,
  }));

  const address = get(jsonld, 'address', []).map((addr) => ({
    '@type': 'PostalAddress',
    streetAddress: addr.street_address,
    addressLocality: addr.address_locality,
    addressRegion: addr.address_region,
    postalCode: addr.postal_code,
    addressCountry: addr.address_country,
  }));

  const sameAs = Array.isArray(socialLinks) ? socialLinks.map((link) => link.link).filter((l) => l) : [];

  const publisher = {
    '@type': 'ProfilePage',
    name,
  };

  const root = {
    '@context': 'http://schema.org',
    '@graph': [],
  };

  root['@graph'].push({
    '@type': 'Organization',
    name,
    legalName,
    url,
    logo,
    foundingDate,
    founders,
    address,
    contactPoint,
    sameAs,
  });

  root['@graph'].push({
    '@type': 'WebSite',
    name,
    url,
  });

  root['@graph'].push({
    '@type': 'WebPage',
    name: page.title,
    description,
    publisher,
  });

  return root;
}
