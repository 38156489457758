import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/opt/build/repo/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import event_bus_client_dfAkhJH2Be from "/opt/build/repo/plugins/event-bus.client.ts";
import gtm_client_OzmBuHLRIb from "/opt/build/repo/plugins/gtm.client.ts";
import pinia_route_RddDQ2dz2j from "/opt/build/repo/plugins/pinia-route.ts";
import index_fvgp7yueDQ from "/opt/build/repo/plugins/scrollspy/index.ts";
import search_MIZqzcnD8X from "/opt/build/repo/plugins/search.ts";
import vue_instantssearch_ebNDSOPW6D from "/opt/build/repo/plugins/vue-instantssearch.ts";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  event_bus_client_dfAkhJH2Be,
  gtm_client_OzmBuHLRIb,
  pinia_route_RddDQ2dz2j,
  index_fvgp7yueDQ,
  search_MIZqzcnD8X,
  vue_instantssearch_ebNDSOPW6D
]