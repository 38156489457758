<template>
  <div class="app" :class="{ 'is-loading': !pageLoaded && passwordProtected }">
    <div v-if="init.loaded">
      <nuxt-layout>
        <nuxt-page :key="route.path" />
      </nuxt-layout>
      <digital-screen-shell />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { withTrailingSlash } from 'ufo';
import { useRuntimeConfig } from '#app';
import { computed } from 'vue';
import { useJsonld } from '#jsonld';
import { useRoute, useHead, navigateTo } from '#imports';
import { useInitStore } from '~/stores/init';
import type { LooseObject } from '~/types/generic';
import { usePagesStore } from '~/stores/pages';
import { generatePageLdJson, generatePageMeta } from '~/tools/meta/meta';
import { useSeoStore } from '~/stores/seo';
import { useGlobalsStore } from '~/stores/globals';
import { useResourcesStore } from '~/stores/resources';
import { useGrantsStore } from '~/stores/grants';
import { checkIfIsPasswordProtected } from '~/utils/passwordProtection';
import { useGtm } from '@gtm-support/vue-gtm';

const nuxtApp = useNuxtApp();
const route = useRoute();
const init = useInitStore();
const seo = useSeoStore();
const globals = useGlobalsStore();
const pagesStore = usePagesStore();
const resourcesStore = useResourcesStore();
const grantsStore = useGrantsStore();
const layout = useLayoutStore();
const digitalScreenStore = useDigitalScreenStore();
const { passwordProtected, baseUrl } = useRuntimeConfig().public;

const pageLoaded = computed(() => layout.pageLoaded);

if (!init.loaded) {
  const runtime = useRuntimeConfig();
  const headerName = runtime.generateHeader;
  const headerValue = runtime.generateValue;

  const resp: LooseObject = await $fetch(`${useRuntimeConfig().public.apiUrl}/api/pt/init`, {
    headers: {
      [headerName]: headerValue,
    },
  });
  init.setData(resp.data || {});
}

nuxtApp.hook('page:loading:start', () => {
  const isPasswordProtected = checkIfIsPasswordProtected(route);

  layout.setPageLoaded(!isPasswordProtected);
});

nuxtApp.hook('page:loading:end', () => {
  layout.setPageLoaded(true);
});

if (pagesStore.currentPage && pagesStore.currentPage.redirect) {
  navigateTo(withTrailingSlash(pagesStore.currentPage.redirect));
}

const metaPage = computed(() => {
  switch (route.meta.pageType) {
    case 'resource':
      if (resourcesStore.currentResource) {
        return resourcesStore.currentResource;
      }
      break;
    case 'grant':
      if (grantsStore.currentGrant) {
        return grantsStore.currentGrant;
      }
      break;
    case 'digital-screen':
      if (digitalScreenStore.currentDigitalScreenPage) {
        return digitalScreenStore.currentDigitalScreenPage;
      }
      break;
    case 'page':
      if (pagesStore.currentPage) {
        return pagesStore.currentPage;
      }
      break;
  }
  return null;
});

const meta = computed(() =>
  generatePageMeta({
    route,
    baseUrl,
    globals: seo.seo,
    page: metaPage.value,
    additionalMeta: passwordProtected ? [{ name: 'robots', content: 'noindex' }] : [],
  })
);

useHead(meta);
useJsonld(() =>
  generatePageLdJson({
    page: metaPage.value,
    socialLinks: globals.socialLinks,
    jsonld: globals.jsonld,
  })
);

const gtm = useGtm();
onMounted(() => {
  window.setTimeout(function () {
    gtm.trackEvent({
      event: 'afterLoad2',
    });
  }, 2000);

  window.setTimeout(function () {
    gtm.trackEvent({
      event: 'afterLoad5',
    });
  }, 5000);
});
</script>
