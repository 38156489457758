import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#imports';
import type { LooseObject, Nullable } from '~/types/generic';

export interface State {
  entry: Nullable<LooseObject>;
  isLoading: boolean;
}

export const useLivePreviewStore = defineStore('live-preview', {
  state: (): State => ({
    entry: null,
    isLoading: false,
  }),

  actions: {
    async load(hash: string) {
      this.isLoading = true;
      try {
        const runtime = useRuntimeConfig();
        const headerName = runtime.generateHeader;
        const headerValue = runtime.generateValue;

        this.entry = await $fetch(`${useRuntimeConfig().public.apiUrl}/api/pt/live-preview/${hash}`, {
          headers: {
            [headerName]: headerValue,
          },
        });
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
      return this.entry;
    },
    reset() {
      this.entry = null;
    },
  },
});
