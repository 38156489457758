import { defineStore } from 'pinia';
import type { LooseObject } from '~/types/generic';

export const usePopupsStore = defineStore('popups', {
  state: () => ({
    currentPopup: null as string | null,
    zoomableImagePopupImage: null as LooseObject | null,
  }),

  actions: {
    openPopup(popupName: string) {
      this.currentPopup = popupName;
    },

    closePopup() {
      this.currentPopup = null;
    },

    setZoomableImagePopupImage(image: LooseObject | null) {
      this.zoomableImagePopupImage = image;
    },
  },
});
