<template>
  <pt-popup v-model="popupOpened" :min-height="false" rounded>
    <div class="digital-screen-popup">
      <div class="digital-screen-popup__content lead-label is-strong">
        Sorry, this link can not be opened here.
        <br />
        Visit publictrust.co.nz from your personal device to access this link.
      </div>

      <div class="digital-screen-popup__actions has-marg-top-m">
        <pt-button type="secondary" @click="handleContinue"> Continue </pt-button>
      </div>
    </div>
  </pt-popup>
</template>

<script lang="ts" setup>
import { DIGITAL_SCREEN_EXTERNAL_LINK_POPUP } from '@/constants/popups';
import { usePopups } from '~/composables/popup';
import PtButton from '~/components/global/elements/PtButton.vue';
import PtPopup from '~/components/global/elements/PtPopup.vue';

const { closePopup, popupOpened } = usePopups(DIGITAL_SCREEN_EXTERNAL_LINK_POPUP);
// const countdown = ref(null);
const handleContinue = () => closePopup();
</script>
