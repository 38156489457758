<template>
  <pt-popup v-model="popupOpened" :min-height="false" rounded>
    <div class="digital-screen-popup">
      <div class="digital-screen-popup__content lead-label is-strong">
        Your session is about to expire in {{ countdown }} seconds.<br />
        Would you like to continue browsing?
      </div>
      <div class="digital-screen-popup__actions has-marg-top-m">
        <pt-button outlined type="secondary" @click="handleReset"> Back to Homepage </pt-button>
        <pt-button type="secondary" @click="handleContinue"> Continue </pt-button>
      </div>
    </div>
  </pt-popup>
</template>

<script lang="ts" setup>
import { DIGITAL_SCREEN_IDLE_TIMEOUT_POPUP } from '@/constants/popups';
import { usePopups } from '~/composables/popup';
import { useDigitalScreenStore } from '~/stores/digital-screen';
import PtButton from '~/components/global/elements/PtButton.vue';
import PtPopup from '~/components/global/elements/PtPopup.vue';

const router = useRouter();
const { popupOpened, closePopup } = usePopups(DIGITAL_SCREEN_IDLE_TIMEOUT_POPUP);
const countdown = ref(null);
const digitalScreenStore = useDigitalScreenStore();

let countdownIntervalHandle = null;

const idlePopupTimeoutSec = computed(() => digitalScreenStore.idlePopupTimeoutSec);

watch(
  popupOpened,
  (val) => {
    if (val) {
      countdown.value = idlePopupTimeoutSec.value;
      countdownIntervalHandle = setInterval(() => handleInterval(), 1000);
    } else if (countdownIntervalHandle) {
      clearInterval(countdownIntervalHandle);
    }
  },
  {
    immediate: true,
  }
);

onBeforeUnmount(() => {
  if (countdownIntervalHandle) {
    clearInterval(countdownIntervalHandle);
  }
});

const handleInterval = () => {
  if (countdown.value === 0) {
    handleReset();
    return;
  }
  countdown.value--;
};

const handleReset = () => {
  closePopup();
  router.push(digitalScreenStore.homePath);
};

const handleContinue = () => {
  closePopup();
};
</script>
