import { defineNuxtPlugin } from '#app';
import { type Algoliasearch, algoliasearch } from 'algoliasearch';

export type SearchConfig = {
  client: Algoliasearch;
  algoliaGrantsAlphabeticalReplica: string;
  algoliaGrantsIndex: string;
};

export default defineNuxtPlugin((nuxtApp) => {
  const { algoliaAppId, algoliaApiKey, algoliaGrantsAlphabeticalReplica, algoliaGrantsIndex } = nuxtApp.$config.public;
  const client: Algoliasearch = algoliasearch(algoliaAppId, algoliaApiKey);

  const searchConfig = {
    client,
    algoliaGrantsAlphabeticalReplica,
    algoliaGrantsIndex,
  };

  nuxtApp.provide('search', searchConfig);
});
