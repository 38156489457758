import { defineStore } from 'pinia';
import { get } from '~/utils/objects';
import type { LooseObject } from '~/types/generic';

interface State {
  globals: LooseObject;
}
export const useGlobalsStore = defineStore('globals', {
  state: (): State => ({
    globals: {},
  }),

  getters: {
    socialLinks(state) {
      const links = [];
      Object.keys(state.globals.social_links || {}).forEach((key) => {
        const link = state.globals.social_links[key];
        if (link) {
          links.push({
            key,
            link,
          });
        }
      });
      return links;
    },

    contact(state) {
      return get(state.globals, 'contacts', {});
    },

    jsonld(state) {
      return get(state.globals, 'jsonld', {});
    },

    booking(state) {
      return get(state.globals, 'booking', {});
    },

    contactPhone(state) {
      return get(state.globals, 'contacts.phone', null);
    },

    contactEmail(state) {
      return get(state.globals, 'contacts.support_email', null);
    },

    loginUrl(state) {
      return get(state.globals, 'contacts.login_url', null);
    },

    announcementBar(state) {
      return get(state.globals, 'announcement_bar', null);
    },

    searchSettings(state) {
      return get(state.globals, 'search');
    },
  },

  actions: {
    setData(globals: any) {
      this.globals = globals;
    },
  },
});
