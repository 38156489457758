import { defineStore } from 'pinia';
import { withoutTrailingSlash } from 'ufo';
import { useRuntimeConfig } from '#app';
import type { LooseObject, Nullable } from '@/types/generic';
import { param } from '~/utils/url';
import { useLivePreviewStore } from '~/stores/live-preview';
import { useResourcesStore } from '~/stores/resources';
import { useGrantsStore } from '~/stores/grants';

export interface Page extends LooseObject {
  id: string;
  title: string;
  url?: string;
  redirect?: string;
  blueprint: {
    handle: string;
  };
  hide_breadcrumbs?: boolean;
  hide_page_title?: boolean;
  hide_navigation?: boolean;
  sections?: Array<LooseObject>;
  seo?: Array<LooseObject>;
}

interface State {
  pages: Record<string, Page>;
  tree: Page[];
}

interface PageResponse extends LooseObject {
  data: Page[];
}

const buildFlatTree = (tree, parent = null) => {
  return tree.reduce((cr, item) => {
    cr.push({
      label: item.page.title,
      to: item.page.url,
      id: item.page.id,
      blueprint: item.page.blueprint && item.page.blueprint.handle ? item.page.blueprint.handle : null,
      parent,
    });

    if (item.children && item.children.length) {
      return cr.concat(buildFlatTree(item.children, item.page.id));
    }
    return cr;
  }, []);
};

const getBreadcrumbsFromFlatTree = (tree, id, crumbs = []) => {
  const item = tree.reduce((curr, branch) => {
    if (branch.id === id) {
      return branch;
    }
    return curr;
  }, null);

  if (item) {
    crumbs.push(item);
  }
  if (item && item.parent) {
    return crumbs.concat(getBreadcrumbsFromFlatTree(tree, item.parent));
  }

  return crumbs;
};

const getResourceBreadcrumb = (resource) => {
  return [
    resource && resource.id
      ? {
          id: resource.id,
          label: resource.title,
          to: resource.url,
        }
      : null,
  ];
};

const getGrantBreadcrumb = (grant, grantsIndex) => {
  return [
    grant && grant.id
      ? {
          id: grant.id,
          label: grant.title,
          to: grant.url,
        }
      : null,
    grantsIndex
      ? {
          id: grantsIndex.id,
          label: grantsIndex.label || grantsIndex.title,
          to: grantsIndex.to,
        }
      : null,
  ];
};

export const usePagesStore = defineStore('pages', {
  state: (): State => ({
    pages: {},
    tree: [],
  }),

  getters: {
    currentPage(state): Nullable<Page> {
      const livePreview = useLivePreviewStore();
      if (livePreview.entry) {
        return livePreview.entry as Page;
      }

      // console.log(
      //   'PATH WITH PAGE',
      //   withoutTrailingSlash(this.router.currentRoute.value.path),
      //   state.pages[withoutTrailingSlash(this.router.currentRoute.value.path)]
      // );

      return state.pages[withoutTrailingSlash(this.router.currentRoute.value.path)] || ({} as Page);
    },

    hasCurrentPage(): boolean {
      return !!(this.currentPage && this.currentPage.id);
    },

    currentBreadcrumbs(): LooseObject[] {
      const livePreview = useLivePreviewStore();
      if (livePreview.entry) {
        return [];
      }

      const crumbs = getBreadcrumbsFromFlatTree(this.flatTree, this.currentPage.id);

      const resourcesStore = useResourcesStore();
      const grantsStore = useGrantsStore();

      if (resourcesStore.hasCurrentResource) {
        crumbs.push(...getResourceBreadcrumb(resourcesStore.getCurrentResource));
      }

      if (grantsStore.hasCurrentGrant) {
        crumbs.push(...getGrantBreadcrumb(grantsStore.getCurrentGrant, this.grantsIndex));
      }

      return crumbs.filter((c) => c).reverse();
    },

    flatTree(state): LooseObject[] {
      return buildFlatTree(state.tree);
    },

    grantsIndex(): LooseObject {
      return this.flatTree.reduce((curr, page) => {
        if (curr) {
          return curr;
        }
        return page.blueprint === 'grants_index' ? page : curr;
      }, null);
    },

    currentPageTemplate(): string {
      if (!this.hasCurrentPage) {
        return '';
      }

      const blueprint = this.currentPage.blueprint && this.currentPage.blueprint.handle ? this.currentPage.blueprint.handle : '';

      return blueprint ? `${blueprint.replace(/_/g, '-')}`.replace('templates-', '') : 'pages';
    },
  },

  actions: {
    async getPage(path: string): Promise<Nullable<Page>> {
      const routePath = withoutTrailingSlash(path);
      if (this.pages[routePath]) {
        return this.pages[routePath];
      }

      const params = param({
        'filter[uri:equals]': routePath,
      });

      const runtime = useRuntimeConfig();
      const headerName = runtime.generateHeader;
      const headerValue = runtime.generateValue;

      const response: PageResponse = await $fetch(`${runtime.public.apiUrl}/api/collections/pages/entries?${params}`, {
        headers: {
          [headerName]: headerValue,
        },
      });

      return response.data && response.data.length ? response.data[0] : null;
    },

    setPage(path: string, page: Nullable<Page>) {
      const routePath = withoutTrailingSlash(path);
      if (this.pages[routePath]) {
        return;
      }
      this.pages[routePath] = page;
    },

    setTree(tree: any) {
      this.tree = tree;
    },
  },
});
