import { BOOLEAN_OPEN_NOW, GRANTS_RESULTS_PER_PAGE, TAXONOMY_REGION, TAXONOMY_SECTORS, TAXONOMY_TYPE } from '~/constants/grants';

export async function getGrantsResultsAlgolia($search, searchParams) {
  const filters = [];
  if (searchParams.type) {
    filters.push(`${TAXONOMY_TYPE}:${searchParams.type}`);
  }

  if (searchParams.region) {
    filters.push(`${TAXONOMY_REGION}:${searchParams.region}`);
  }

  if (searchParams.sector) {
    filters.push(`${TAXONOMY_SECTORS}:${searchParams.sector}`);
  }

  if (searchParams.openOnly === true) {
    filters.push(`${BOOLEAN_OPEN_NOW}:true`);
  }

  return await $search.client.searchSingleIndex({
    indexName: searchParams.query ? $search.algoliaGrantsIndex : $search.algoliaGrantsAlphabeticalReplica,
    searchParams: {
      query: searchParams.query,
      hitsPerPage: GRANTS_RESULTS_PER_PAGE,
      page: searchParams.page,
      filters: filters.length ? filters.join(' AND ') : undefined,
    },
  });
}
