import type { DirectiveBinding } from 'vue';

const findFocusable = (element: HTMLElement, programmatic = false): NodeListOf<HTMLElement> | null => {
  if (!element) {
    return null;
  }
  if (programmatic) {
    return element.querySelectorAll('*[tabindex="-1"]');
  }
  return element.querySelectorAll(`a[href]:not([tabindex="-1"]),
                                     area[href],
                                     input:not([disabled]),
                                     select:not([disabled]),
                                     textarea:not([disabled]),
                                     button:not([disabled]),
                                     iframe,
                                     object,
                                     embed,
                                     *[tabindex]:not([tabindex="-1"]),
                                     *[contenteditable]`);
};

let onKeyDown: (event: KeyboardEvent) => void;

const bind = (el: HTMLElement, binding: DirectiveBinding) => {
  if (binding.value) {
    let focusable = findFocusable(el);
    let focusableProg = findFocusable(el, true);

    if (focusable && focusable.length > 0) {
      onKeyDown = (event: KeyboardEvent) => {
        focusable = findFocusable(el);
        focusableProg = findFocusable(el, true);
        const firstFocusable = focusable[0];
        const lastFocusable = focusable[focusable.length - 1];

        if (event.target === firstFocusable && event.shiftKey && event.key === 'Tab') {
          event.preventDefault();
          lastFocusable.focus();
        } else if (
          (event.target === lastFocusable || Array.from(focusableProg).includes(event.target as HTMLElement)) &&
          !event.shiftKey &&
          event.key === 'Tab'
        ) {
          event.preventDefault();
          firstFocusable.focus();
        }
      };
      el.addEventListener('keydown', onKeyDown);
    }
  }
};

const unbind = (el: HTMLElement) => {
  el.removeEventListener('keydown', onKeyDown);
};

export default {
  beforeMount: bind,
  unmounted: unbind,
};
