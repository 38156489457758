<template>
  <component :is="computedTag" class="button" v-bind="bindings" :class="rootClasses">
    <template v-if="label">
      {{ label }}
    </template>
    <template v-else-if="$slots.default">
      <slot />
    </template>
  </component>
</template>

<script setup lang="ts">
import { computed, useAttrs } from 'vue';
import AppLink from '~/components/AppLink';
import PtLinkAction from '~/components/global/elements/PtLinkAction.vue';

const props = defineProps({
  type: {
    type: [String, Object],
    default: null,
  },
  size: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  loading: Boolean,
  outlined: Boolean,
  expanded: Boolean,
  hovered: Boolean,
  wide: Boolean,
  wider: Boolean,
  nativeType: {
    type: String,
    default: 'button',
    validator: (value: string) => ['button', 'submit', 'reset'].includes(value),
  },
  tag: {
    type: String,
    default: 'button',
    validator: (value: string) => ['a', 'button', 'input', 'router-link', 'app-link', 'pt-link-action'].includes(value),
  },
});

const attrs = useAttrs();

const computedTag = computed(() => {
  if (attrs.disabled !== undefined && attrs.disabled !== false) {
    return 'button';
  }
  switch (props.tag) {
    case 'pt-link-action':
      return PtLinkAction;
    case 'app-link':
      return AppLink;
    default:
      return props.tag;
  }
});

const bindings = computed(() => {
  const bindings = {
    ...attrs,
  };
  if (['button', 'input'].includes(props.tag)) {
    bindings.type = props.nativeType;
  }
  return bindings;
});

const rootClasses = computed(() => {
  // eslint-disable-next-line
  const rootClasses: string | {}[] = [
    {
      'is-loading': props.loading,
      'is-outlined': props.outlined,
      'is-full': props.expanded,
      'is-hovered': props.hovered,
      'is-wide': props.wide,
      'is-wider': props.wider,
    },
  ];

  if (props.size) {
    rootClasses.push(`is-${props.size}`);
  }

  if (props.type) {
    rootClasses.push(`is-${props.type}`);
  }

  return rootClasses;
});
</script>
