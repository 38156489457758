import { BREAKPOINTS } from '~/constants/layout';
import { detectTouch } from '~/utils/detect-touch';
import { debounce } from '~/utils/debounce';
import { throttle } from '~/utils/throttle';

const doc = document.documentElement;
const scrollTopOffset = 40;
const scrollHeaderOffset = 150;

function getScrollOffsetEl() {
  const scrollHeaderOffsetEl = document.querySelector('[data-scroll-header-offset-el]') as HTMLElement;

  if (scrollHeaderOffsetEl) {
    return {
      scrollHeaderOffsetEl,
      scrollHeaderOffsetElAfter: scrollHeaderOffsetEl.dataset.scrollHeaderOffsetEl === 'after',
    };
  }

  return {};
}

function getScrollTop() {
  return window.scrollY || doc.scrollTop;
}

function scrollHeaderVisibleOffset() {
  const { scrollHeaderOffsetEl, scrollHeaderOffsetElAfter } = getScrollOffsetEl();
  if (!scrollHeaderOffsetEl) return scrollHeaderOffset;

  const bounds = scrollHeaderOffsetEl.getBoundingClientRect();
  return scrollHeaderOffsetElAfter ? scrollHeaderOffsetEl.offsetTop + bounds.height : scrollHeaderOffsetEl.offsetTop;
}

export default defineNuxtPlugin((nuxtApp) => {
  const busState = reactive({
    isTouchDevice: detectTouch(),
    // sizes
    windowWidth: 800,
    windowHeight: 800,
    // scroll
    scrollTimer: null,
    scrollFlag: false,
    lastScrollPosition: getScrollTop(),
    scrollDirection: '',

    isWidescreen: computed(() => busState.windowWidth >= BREAKPOINTS.widescreen),
    isDesktop: computed(() => busState.windowWidth >= BREAKPOINTS.desktop),
    isTablet: computed(() => busState.windowWidth >= BREAKPOINTS.tablet),
    isMobile: computed(() => busState.windowWidth < BREAKPOINTS.tablet),
    isTiny: computed(() => busState.windowWidth < BREAKPOINTS.mobile),
    isMobileLegacy: computed(() => busState.windowWidth < BREAKPOINTS.mobileLegacy),
  });

  nuxtApp.hook('vue:setup', () => {
    onMounted(() => {
      initScroll();
      initResize();
    });

    onBeforeUnmount(() => {
      destroyResize();
      destroyScroll();
    });
  });

  const doResize = () => {
    busState.windowWidth = window.innerWidth;
    busState.windowHeight = window.innerHeight;
  };

  const initResize = () => {
    window.addEventListener('resize', debouncedResize, {
      passive: true,
      capture: false,
    });
    doResize();
  };

  const destroyResize = () => {
    window.addEventListener('resize', debouncedResize, {
      passive: true,
      capture: false,
    });
  };

  const debouncedResize = debounce(doResize, 100);

  const doScroll = () => {
    const scrollTop = getScrollTop();

    if (scrollTop > 0) {
      doc.classList.add('is-scrolled');
    } else {
      doc.classList.remove('is-scrolled');
    }
    if (scrollTop > scrollTopOffset) {
      doc.classList.remove('is-top');
    } else {
      doc.classList.add('is-top');
    }

    if (scrollTop > scrollHeaderVisibleOffset()) {
      doc.classList.add('is-scroll-header-visible');
    } else {
      doc.classList.remove('is-scroll-header-visible');
    }

    if (busState.lastScrollPosition > scrollTop) {
      busState.scrollDirection = 'down';
      doc.classList.remove('is-scrolling-down');
      doc.classList.add('is-scrolling-up');
    } else if (scrollTop > busState.lastScrollPosition) {
      busState.scrollDirection = 'up';
      doc.classList.add('is-scrolling-down');
      doc.classList.remove('is-scrolling-up');
    } else {
      busState.scrollDirection = 'still';
    }
    busState.lastScrollPosition = scrollTop;
  };

  const initScroll = () => {
    window.addEventListener('scroll', throttledScroll, {
      passive: true,
      capture: false,
    });
    doScroll();
  };

  const destroyScroll = () => {
    window.removeEventListener('scroll', throttledScroll);
    busState.scrollTimer = null;
    busState.scrollFlag = false;
    busState.lastScrollPosition = getScrollTop();
  };

  const throttledScroll = throttle(doScroll, 100);
});
